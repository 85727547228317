*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 6px;
}

#divider {
  display: block;
  width: 100%;
  height: 0.1px;
  background-color: #ccc;
  margin: 20px 0;
}

#icon_btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_page {
  font-size: 22px;
  font-weight: 500;
  color: var(--white-black);
  margin-bottom: 40px;
}

.big_title {
  font-size: 28px;
  font-weight: 500;
  color: var(--white-black);
}

.link_color {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #1890ff;
  transition: all 0.4s;
}

.link_color:hover {
  opacity: 76%;
}

.link_text {
  font-size: 16px;
  color: #1890ff;
  transtition: all 0.4s;
}
.link_text:hover {
  opacity: 76%;
}

.small_text {
  font-size: 13px;
  color: var(--white-black);
}
.middle_text {
  font-size: 14px;
  font-weight: 500;
  color: var(--white-black);
}

.page {
  width: 100%;
  height: 100%;
}

.adminka_header {
  width: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}
.adminka_title {
  color: var(--white-black);
}
.content_title {
  font-weight: 400;
  color: var(--white-black);
}

@media print {
  .no_print {
    display: none !important;
  }
}
