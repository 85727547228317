* {
  margin: 0;
  outline: none;
  background: none;
  border: none;
  box-sizing: border-box;
  transition: color 0.2s ease-in, background-color 0.2s ease-in;
  text-decoration: none;
}

.App {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.App.dark {
  --header-footer-back: #2b333b;
  --mobile-bg: #000;
  --simple-bg: #212529;
  --second-bg: #212529;
  --drop-bg: #2b333b;
  --third-bg: #141617;
  --img-bg: rgba(0, 0, 0, 0.82);
  --contrast-bg: rgb(50, 50, 53);
  --white-black: white;
  --black-white: black;
  --black: #000;
  --white: #fff;
  --table-border: rgb(244, 244, 244);
  --table-bg: rgb(50, 50, 53);
  --accordion-bg: rgb(50, 50, 53);
  --text: #0081d6;
  --blue: #1890ff;
  --silver: #dedede;
  --gray: #838181;
  --background: rgb(245, 252, 255);
  --light-blue: #e6f7ff;
  --border-blue: #91d5ff;
  --ui-disabled-background: #1c1b1f1f;
  --ui-black: #000000;
  --shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  --dash-bg: #000000;
  --dash-bg-con: #000000;
  --dash-shadow: none;
  --dash-color: rgba(255, 255, 255, 0.777);
  --test: red;
  --silwhi: silver;

  color-scheme: dark;
}

:root {
  --header-footer-back: #267bca;
  --col-dark: transparent;
  --col-darkGray: #52555a;
  --col-gray: #aeaeae;
  --mobile-bg: #0081d6;
  --simple-bg: #fbfeffe2;
  --second-bg: #265e99;
  --drop-bg: #5899df;
  --third-bg: #0081d6;
  --img-bg: transparent;
  --contrast-bg: rgb(255, 255, 255);
  --white-black: black;
  --black-white: white;
  --black: #000;
  --white: #fff;
  --table-border: rgba(224, 224, 224, 1);
  --table-bg: rgb(255, 255, 255);
  --accordion-bg: rgba(215, 214, 214, 0.091);
  --text: #0081d6;
  --blue: #1890ff;
  --silver: #dedede;
  --gray: #838181;
  --background: rgb(245, 252, 255);
  --light-blue: #e6f7ff;
  --border-blue: #91d5ff;
  --ui-disabled-background: #1c1b1f1f;
  --ui-black: #000000;
  --shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  --dash-bg: #001529;
  --dash-bg-con: rgb(255, 255, 254);
  --dash-color: rgba(255, 255, 255, 0.777);
  --dash-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.24),
    0 3px 5px 0 rgba(0, 0, 0, 0.24);
  --test: blue;
  --silwhi: gray;

  color-scheme: light;
}
