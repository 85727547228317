@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.menu_container {
  font-family: 'Roboto Flex', sans-serif;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-color: #f8f8f8;
  gap: 20px;
  overflow: hidden;
  transition: height 0.6s ease, padding 0.6s ease, opacity 0.6s ease;

  .item_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    h4 {
      letter-spacing: 0.6px;
      font-weight: 500;
      font-size: 16px;
    }
    .list {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-radius: 4px;
      border-radius: 1px solid silver;
      li {
        width: fit-content;
        height: fit-content;
        border: 1px solid silver;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
      li:first-child {
        border-radius: 4px 0 0 4px;
      }

      li:last-child {
        border-radius: 0 4px 4px 0;
      }

      //!! additional

      .additional {
        padding: 0 10px;
        height: 30.2px;
        transition: 0.4s;
        cursor: pointer;
      }
      .additional_active {
        background-color: rgb(214, 211, 211);
        padding: 0 10px;
        height: 30.2px;
        transition: 0.4s;
        cursor: pointer;
      }

      .additional:hover {
        background-color: rgb(214, 211, 211);
      }

      //!! image

      .image {
        padding: 0 10px;
        height: 30.2px;
        transition: 0.4s;
        cursor: pointer;
      }

      .image_active {
        background-color: rgb(214, 211, 211);
        padding: 0 10px;
        height: 30.2px;
        transition: 0.4s;
        cursor: pointer;
      }

      .image:hover {
        background-color: rgb(214, 211, 211);
      }

      // !! fonts
      .f14 {
        font-size: 14px;
        font-weight: 500;
        height: 30.2px;
        width: 30.2px;
        cursor: pointer;
        transition: 0.4s;
      }
      .f16 {
        font-size: 16px;
        font-weight: 500;
        height: 30.2px;
        width: 30.2px;
        cursor: pointer;
        transition: 0.4s;
      }
      .f17 {
        font-size: 17px;
        font-weight: 600;
        height: 30.2px;
        width: 30.2px;
        cursor: pointer;
        transition: 0.4s;
      }
      .f18 {
        font-size: 18px;
        font-weight: 600;
        height: 30.2px;
        width: 30.2px;
        cursor: pointer;
        transition: 0.4s;
      }
      .f19 {
        font-size: 19px;
        font-weight: 700;
        height: 30.2px;
        width: 30.2px;
        cursor: pointer;
        transition: 0.4s;
      }
      .f14_active {
        background-color: rgb(214, 211, 211);
        font-size: 14px;
        font-weight: 500;
        height: 30.2px;
        width: 30.2px;
        cursor: pointer;
        transition: 0.4s;
      }
      .f16_active {
        background-color: rgb(214, 211, 211);
        font-size: 16px;
        font-weight: 500;
        height: 30.2px;
        width: 30.2px;
        cursor: pointer;
        transition: 0.4s;
      }
      .f17_active {
        background-color: rgb(214, 211, 211);
        font-size: 17px;
        font-weight: 600;
        height: 30.2px;
        width: 30.2px;
        cursor: pointer;
        transition: 0.4s;
      }
      .f18_active {
        background-color: rgb(214, 211, 211);
        font-size: 18px;
        font-weight: 600;
        height: 30.2px;
        width: 30.2px;
        cursor: pointer;
        transition: 0.4s;
      }
      .f19_active {
        background-color: rgb(214, 211, 211);
        font-size: 19px;
        font-weight: 700;
        height: 30.2px;
        width: 30.2px;
        cursor: pointer;
        transition: 0.4s;
      }
      .f14:hover {
        background-color: rgb(214, 211, 211);
      }
      .f16:hover {
        background-color: rgb(214, 211, 211);
      }
      .f17:hover {
        background-color: rgb(214, 211, 211);
      }
      .f18:hover {
        background-color: rgb(214, 211, 211);
      }
      .f19:hover {
        background-color: rgb(214, 211, 211);
      }

      //?? Colors
      .whiteblack {
        cursor: pointer;
        padding: 0 10px;
        height: 30.2px;
        background-color: white;
        color: black;
      }
      .blackwhite {
        cursor: pointer;
        padding: 0 10px;
        height: 30.2px;
        background-color: black;
        color: white;
      }
      .blueblack {
        cursor: pointer;
        padding: 0 10px;
        height: 30.2px;
        background-color: #9dd1ff;
        color: black;
      }
      .yellowblack {
        cursor: pointer;
        padding: 0 10px;
        height: 30.2px;
        background-color: #f7f3d6;
        color: black;
      }
      .browngreen {
        cursor: pointer;
        padding: 0 10px;
        height: 30.2px;
        background-color: #3b2716;
        color: #a9e44d;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .menu_container {
    padding: 20px 0;
  }
}

@media screen and (max-width: 700px) {
  .menu_container {
    .item_container {
      flex-wrap: wrap;
    }
  }
}
