@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

.App {
  font-family: 'Roboto Flex', sans-serif !important;
  .with {
    font-family: 'Bitter', serif;
  }
  .options {
    width: 100%;
    height: 100%;
  }

  .options.f14 {
    * {
      font-size: 14px !important;
    }
  }

  .options.f16 {
    * {
      font-size: 16px !important;
    }
  }

  .options.f17 {
    * {
      font-size: 17px !important;
    }
  }

  .options.f18 {
    * {
      font-size: 18px !important;
    }
  }

  .options.f19 {
    * {
      font-size: 19px !important;
    }
  }

  // letter spacing

  .options.middle {
    * {
      letter-spacing: 0.8px;
    }
  }
  .options.big {
    * {
      letter-spacing: 1.5px;
    }
  }

  // line height

  .options.lh40 {
    * {
      line-height: 40px;
    }
  }
  .options.lh30 {
    * {
      line-height: 30px;
    }
  }

  // image filter

  .options.grayscale {
    * {
      img {
        filter: grayscale(100%) !important;
      }
      #img_wrapper {
        * {
          filter: grayscale(100%) !important;
        }
      }
    }
  }
  .options.off {
    * {
      img {
        visibility: hidden;
      }
      #img_wrapper {
        * {
          visibility: hidden;
        }
      }
    }
  }

  ///////
  .options.whiteblack {
    * {
      background-color: white !important;
      color: black !important;
    }
  }
  .options.blackwhite {
    * {
      background-color: black !important;
      color: white !important;
    }
  }
  .options.blueblack {
    * {
      background-color: #9dd1ff !important;
      color: black !important;
    }
  }
  .options.yellowblack {
    * {
      background-color: #f7f3d6 !important;
      color: black !important;
    }
  }
  .options.browngreen {
    * {
      background-color: #3b2716 !important;
      color: #a9e44d !important;
      border-color: #a9e44d !important;
      #icon {
        color: #a9e44d !important;
      }
    }
  }
}
