.back_loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3;
}

.background_blur {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  /* background-color: white; */
  filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: 3;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background: transparent;
  border: 3px solid rgba(0, 102, 255, 0.1);
  border-radius: 50%;
  z-index: 3;
}

.loader::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid #0066ff;
  border-right: 3px solid #0066ff;
  border-radius: 50%;
  animation: animateC 1s linear infinite;
}

.loader .line {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 1s linear infinite;
}

.loader .line::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #00aeff;
  top: -6px;
  right: -8px;
  box-shadow: 0 0 20px 5px #0066ff;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

.wrapper_image {
  width: 145px;
  height: 145px;
  border-radius: 999px;
  overflow: hidden;
  position: relative;
}

.image_gerb {
  width: 145px;
  height: 145px;
  z-index: 6;
}

.shine {
  position: absolute;
  top: -200px;
  left: -120px;
  content: 'cecsss';
  width: 400px;
  height: 50px;
  background: #fff;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.5) 100%
  );
  transition: 0.6s;
  transform: rotate(-30deg);
  z-index: 10;
  animation: shideDown 1s infinite;
}

.image_gerb:hover > .shine {
  top: 200px;
}

@keyframes shideDown {
  0% {
    top: -200px;
  }
  100% {
    top: 200px;
  }
}
